import React, { useState, useRef, useEffect } from "react";
import { Modal, TextField, Checkbox, Text, Toggle, TagPicker, DirectionalHint } from "@fluentui/react";
import styles from "../SeachComponent/SearchPopup.module.css";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Stack, DefaultButton } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { axiosPrivateCall } from "../../constants";
import { MessageBar, MessageBarType } from "@fluentui/react";
const cancelIcon = { iconName: "Cancel" };
const InfoIcon = { iconName: "Info" };

const messageBarStyles = {
  root: {
    backgroundColor: "#FFD2D2",
    color: "#A80000",
    margin: "0 auto",
    marginBottom: "20px",
  },
};

const addStyle = {
  paddingLeft: "8px",
  fontWeight: "700",
  fontSize: "10px",
};

const customCheckboxStyles = {
  root: {},
  checkbox: {
    width: "14px",
    height: "14px",
  },
  label: {},
};

const buttonStyles = {
  margin: "5px",
  padding: "8px 16px",
};

const iconStyles = {
  root: {
    marginLeft: "5px",
    fontWeight: "600",
    fontSize: "14px",
  },
};

const getTextFieldStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  return {
    fieldGroup: {
      width: width,
      marginTop: marginTop,
      // height: "35px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: "4px",
      boxSizing: "border-box",
      selectors: {
        ":focus": { borderColor: borderColor },
        ":hover": { borderColor: borderColor },
      },
    },
    field: {
      color: "#5B5F62",
      selectors: {
        "&::placeholder": {
          color: titleColor,
          fontSize: 14,
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const getDropdownStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  const borderRadius = "4px";
  return {
    title: {
      height: "32px",
      width: width,
      marginTop: "1px",
      maxWidth: maxWidth,
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      marginTop: marginTop,
      borderRadius: borderRadius,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: borderRadius,
        },
      },
    },
  };
};

const getTagPickerStyles = (width, marginTop, maxWidth) => ({
  root: {
    width: width,
    marginTop: marginTop,
    maxWidth: maxWidth,
    border: "1px solid #E1E5E8",
    borderRadius: "4px",
    boxSizing: "border-box",
    position: "relative", // Add this
    selectors: {
      ":hover": {
        borderColor: "#D7D7D7",
      },
      ":focus-within": {
        borderColor: "#484848",
      },
    },
  },
  input: {
    width: width,
    padding: "8px",
    color: "#5B5F62",
    position: "relative", // Add this
    zIndex: 1, // Add this
    "::placeholder": {
      color: "#D7D7D7",
    },
  },
  text: {
    border: "none",
  },
});

const getSuggestionsStyles = (title) => ({
  suggestionsContainer: {
    ".ms-Suggestions": {
      width: `${
        title.toLowerCase() === "industry" || title.toLowerCase() === "department" ? "768px" : "538px"
      } !important`,
    },
    ".ms-Suggestions-container": {
      width: `${
        title.toLowerCase() === "industry" || title.toLowerCase() === "department" ? "768px" : "538px"
      } !important`,
    },
    ".ms-Suggestions-itemButton": {
      width: `${
        title.toLowerCase() === "industry" || title.toLowerCase() === "department" ? "768px" : "538px"
      } !important`,
    },
  },
});


const SearchPopup = ({ onDismiss, candidate }) => {
  const [searchPopUp, setSearchPopUp] = useState(true);
  const [message, setMessage] = useState(null);

  //keyword states
  const [keywordToggle, setKeywordToggle] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [keywordTags, setKeywordTags] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");

  //exclude keyword states
  const [showExclude, setShowExclude] = useState(false);
  const [excludeTags, setExcludeTags] = useState([]);
  const [excludeInput, setExcludeInput] = useState("");

  // IT fields state
  const [showItFields, setShowItFields] = useState(false);
  const [itText, setItText] = useState("+ Add IT Skills");
  const [itInputFields, setItInputFields] = useState([{ id: 1, skill: "", experience: "", mustHave: false }]);

  //experience states
  const [minExperienceMonth, setMinExperienceMonth] = useState("");
  const [minExperienceYear, setMinExperienceYear] = useState("");
  const [maxExperienceMonth, setMaxExperienceMonth] = useState("");
  const [maxExperienceYear, setMaxExperienceYear] = useState("");

  // salary states
  const [maxSalary, setMaxSalary] = useState("");
  const [minSalary, setMinSalary] = useState("");

  //location states
  const [locations, setLocations] = useState([]);
  const [locationValue, setLoctionValue] = useState([]);
  const [relocateCheckbox, setrelocateCheckbox] = useState(false);
  const [anywhereCheckBox, setAnyWhereCheckBox] = useState(false);

  // States for Employment Details
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDesgination, setSelectedDesgination] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companyToggle, setCompanyToggle] = useState(false);
  const [excludeToggle, setExcludeToggle] = useState(false);
  const [selectedExcludeCompanies, setSelectedExcludeCompanies] = useState([]);
  const [employmentDetails, setEmploymentDetails] = useState({
    companyNames: [],
    industryTypes: [],
    jobRoles: [],
  });
  const [includeCompanyInput, setIncludeCompanyInput] = useState("");
  const [excludeCompanyInput, setExcludeCompanyInput] = useState("");
  const [includeCompanyTags, setIncludeCompanyTags] = useState([]);
  const [excludeCompanyTags, setExcludeCompanyTags] = useState([]);
  const [dropdownSelections, setDropdownSelections] = useState({
    company: "currentCompany",
    excludeCompany: "currentCompany",
    designation: "currentCompany",
  });
  const [selectedNoticePeriods, setSelectedNoticePeriods] = useState([]);

  // others states
  const [isFirstDivOpen, setIsFirstDivOpen] = useState(false);
  const [isSecondDivOpen, setIsSecondDivOpen] = useState(false);
  const inputRef = useRef(null);
  const [designationToggle, setDesignationToggle] = useState(false);
  const [selectedTags3, setSelectedTags3] = useState([]);
  const [experience, setExperience] = useState({
    job_role: "",
    industry_type: "",
    company_name: "",
    designation: "",
    notice_period: [],
    gender: "",
    employment_type: "",
    work_model: "",
    resume_url: "",
    expected_ctc: "",
    current_location: [],
  });
  const [generateButtonVisible, setGenerateButtonVisible] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");
  const [isThirdDivOpen, setIsThirdDivOpen] = useState(false);
  const [ExcludeOn, setExcludeOn] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({
    resume: false,
    mobile: false,
    email: false,
  });

  //functions

  const handleNumericInput = (value, setValue, maxValue = 99) => {
    if (/^\d{0,2}$/.test(value) && Number(value) <= maxValue) {
      setValue(value);
    }
  };
  
  // Single handler for all dropdowns
  const handleDropdownChange = (dropdownType, e, newValue) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [dropdownType]: newValue.key,
    }));
  };

  const handleVerificationToggle = (field) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const renderButton = (field, label) => (
    <DefaultButton onClick={() => handleVerificationToggle(field)} styles={buttonStyles} borderless>
      {label}
      <Icon
        iconName={verificationStatus[field] ? "Accept" : "Add"}
        styles={{
          root: {
            ...iconStyles.root,
            fontWeight: verificationStatus[field] ? "600" : "700",
          },
        }}
      />
    </DefaultButton>
  );

  const handleInputChange = (newValue) => {
    setKeywordInput(newValue);
    setKeywordTags((prevTags) => prevTags.map((tag) => ({ ...tag, isMandatory: false })));
  };

  const handleInputChange1 = (newValue) => {
    setExcludeInput(newValue);
    setExcludeTags((prevTags) => prevTags.map((tag) => ({ ...tag, isMandatory: false })));
  };
  
  const buttonStyle = (buttonText) => ({
    borderless: true,
    borderRadius: "30px",
    backgroundColor: selectedButton === buttonText ? "lightblue" : "transparent",
  });

  const handleButtonClickGender = (buttonText) => {
    setSelectedButton(buttonText);
  };

  const handleButtonClickz = (buttonName) => {
    setSelectedNoticePeriods((prevSelectedNoticePeriods) => {
      const updatedSelectedNoticePeriods = prevSelectedNoticePeriods.includes(buttonName)
        ? prevSelectedNoticePeriods.filter((item) => item !== buttonName)
        : [...prevSelectedNoticePeriods, buttonName];
      if (updatedSelectedNoticePeriods[0] !== "any") {
        setExperience((prevExperience) => ({
          ...prevExperience,
          notice_period: [...updatedSelectedNoticePeriods],
        }));
      }
      return updatedSelectedNoticePeriods;
    });
  };

  const isButtonClicked = (buttonName) => {
    return selectedNoticePeriods.includes(buttonName);
  };

  const optionsjob = [
    { key: "Permanent", text: "Permanent", value: "permanent" },
    { key: "Full time", text: "Full time", value: "Full time" },
    { key: "Contract", text: "Contract", value: "Contract"},
  ];

  const companyoptions = [
    { key: "currentCompany", text: "Current Company" },
    { key: "previousCompany", text: "Previous Company" },
    { key: "currentOrPreviousCompany", text: "Current or Previous Company" },
  ];

  const workmodeoptions = [
    { key: "Home", text: "Home", value: "Home" },
    { key: "Office",text: "Office", value: "Office"},
    { key: "Hybrid",text: "Hybrid", value: "Hybrid"},
  ];

 // Fetch data on component mount
  useEffect(() => {
    axiosPrivateCall
      .post("/api/v1/adbSearch/getEmploymentDetailsArrays")
      .then((res) => {
        setEmploymentDetails(res.data);
      })
      .catch((err) => {
        console.error("Error fetching employment details:", err);
      });
  }, []);

  // Filter suggestions based on input
  const filterSuggestions = (filterText, tagList, array) => {
    if (!filterText) {
      return [];
    }
    return array
      .filter(
        (item) =>
          item && // Ensure item is not null or undefined
          item.toLowerCase().startsWith(filterText.toLowerCase()) && // Match items starting with filterText
          !tagList.some((tag) => tag.name === item)
      )
      .map((item) => ({ key: item, name: item }))
      .slice(0, 10);
  };

  const renderDropdown = (title, selectedItems, setSelectedItems, dataArray, placeholder) => (
    <div>
      <TagPicker
        selectedItems={selectedItems}
        onResolveSuggestions={(filterText, tagList) => 
          filterSuggestions(filterText, tagList, dataArray)
        }
        onChange={(items) => setSelectedItems(items || [])}
        pickerSuggestionsProps={{
          suggestionsHeaderText: "Suggested " + title,
          noResultsFoundText: "No results found",
          styles: getSuggestionsStyles(title),
          className: "custom-suggestions",
          calloutProps: {
            directionalHint: DirectionalHint.bottomLeftEdge,
            gapSpace: 2,
            beakWidth: 0,
            setInitialFocus: true,
            doNotLayer: false,
            directionalHintFixed: true,
            alignTargetEdge: true, // Add this to force alignment with target
            preventDismissOnEvent: true, // Add this to prevent unwanted dismissal
            styles: {
              root: {
                position: "absolute",
                width: "100%",
                zIndex: 1000,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                left: "0 !important", // Force left alignment
                right: "auto !important" // Prevent right alignment
              },
              calloutMain: {
                width: "100% !important"
              }
            },
            // Add bounds to prevent repositioning
            bounds: {
              top: 0,
              bottom: window.innerHeight,
              left: 0,
              right: window.innerWidth
            }
          }
        }}
        inputProps={{
          placeholder: placeholder,
        }}
        itemLimit={10}
        styles={
          title === "Industry" || title === "Department"
            ? getTagPickerStyles("775px", "2px", "775px")
            : getTagPickerStyles("550px", "2px", "550px")
        }
        removeButtonAriaLabel="Remove"
      />
    </div>
  );

  // Single handler for all toggles
const handleToggle = (setToggleState) => (ev, checked) => {
  setToggleState(checked);
  };
  
  // Common Toggle component
const CustomToggle = ({ checked, onChange }) => (
  <Toggle
    onText="Boolean on"
    offText="Boolean off"
    checked={checked}
    onChange={onChange}
  />
);

  const handleCheckboxChange1 = (event, checked) => {
    setrelocateCheckbox(checked);
  };

  const handleCheckboxChange2 = (event, checked) => {
    setAnyWhereCheckBox(checked);
  };

  const formatkeywordTags = keywordTags.map((multiTags) => multiTags.name);
  const starValues = keywordTags.filter((prev) => prev.isMandatory === true).map((item) => item.name);
  const excludeKeywordTags = excludeTags.map((multiTags) => multiTags.name);
  const booleanSearchCompanies = includeCompanyTags.map((multiTags) => multiTags.name);
  
  const annual_salary = {
    minimum_salary: minSalary,
    maximum_salary: maxSalary,
  };

  const searchContent = {
    //keyword skill search
    search: formatkeywordTags,
    booleansearch: keywordToggle,
    starvalues: starValues,
    unavi: excludeKeywordTags,
    itSkills: itInputFields,
    minExpYear: minExperienceYear,
    maxExpYear: maxExperienceYear,
    minExpMonth: minExperienceMonth,
    maxExpMonth: maxExperienceMonth,
    annual_salary: annual_salary,
    current_Location: locations,
    prefer_to_relocate: relocateCheckbox,
    relocate_anywhere: anywhereCheckBox,
    //employment details
    departmentRole: selectedRoles,
    industry: selectedIndustries,
    company_names: companyToggle ? booleanSearchCompanies : selectedCompanies,
    company_boolean: companyToggle,
    company_search_in: dropdownSelections.company,
    exclude_company:  selectedExcludeCompanies,
    exclude_boolean: excludeToggle,
    exclude_search_in: dropdownSelections.excludeCompany,
    designation_names: selectedDesgination,
    designation_boolean: designationToggle,
    designation_search_in: dropdownSelections.designation,
    notice_period: experience.notice_period,
    //diversity and additional details
    gender: selectedButton === "All" ? "" : selectedButton,
    employment_type: experience.employment_type,
    workmode: experience.work_model,
    isResumeAttached: experience.resume_url,
    verificationStatus: verificationStatus,
  };

  function handleKeywordsToggle(ev, checked) {
    setKeywordToggle(checked);
    const newTags = keywordTags.filter((tag) => tag.key !== "");
    setKeywordTags(newTags);
    setKeywordInput("");
    setShowExclude(false);
  }

  function excludeOpen() {
    setShowExclude(!showExclude);
    setKeywordToggle(false);
  }
  const toggleFirstDiv = () => {
    setIsFirstDivOpen(!isFirstDivOpen);
  };

  const toggleThirdtDiv = () => {
    setIsThirdDivOpen(!isThirdDivOpen);
  };

  const stateSetters = {
    0: {
      keywordTags: setKeywordTags,
      keywordInput: setKeywordInput,
    },
    1: {
      excludeTags: setExcludeTags,
      excludeInput: setExcludeInput,
    },
    2: {
      selectedCompanies: setSelectedCompanies,
    },
    3: {
      selectedExcludeCompanies: setSelectedExcludeCompanies,
    },
    4: {
      selectedDesgination: setSelectedDesgination,
    },
  };
  
  const handleClear = (index) => {
    const setters = stateSetters[index];
    if (!setters) return;
    Object.values(setters).forEach((setter) => {
      if (typeof setter === "function") setter([]);
    });
  };
  
  const ClearButton = ({ index, selectedTags, className }) => {
    const showButton = selectedTags.length > 0; // Show button only if there are selected tags.
    return (
      showButton && (
        <div onClick={() => handleClear(index)} className={className}>
          Clear All
        </div>
      )
    );
  };
  
  const starHandle = (tagKey) => {
    setKeywordTags((prevTags) =>
      prevTags.map((tag) => (tag.key === tagKey ? { ...tag, isMandatory: !tag.isMandatory } : tag))
    );
    setMandatory(false);
  };
  const handleModalDismiss = () => {
    setSearchPopUp(false);
    onDismiss();
  };

  const onTagChange = (items) => {
    setKeywordTags(items);
  };

  const onTagChange1 = (items) => {
    setExcludeTags(items);
  };

  const handleTabKeyPress = (event) => {
    if ((event.key === "Tab" || event.key === ",") && keywordInput !== "") {
      event.preventDefault();
      const tags = keywordInput
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== "");
      if (tags.length > 0) {
        const newTags = tags.map((tag) => ({ key: tag, name: tag }));
        const isDuplicate = keywordTags.some((i) => i.key === tags[0]);
        if (isDuplicate) {
          setMessage(`${tags[0]} already added!`);
        } else {
          setKeywordTags([...keywordTags, ...newTags]);
          setMessage(null);
        }
      }
      setKeywordInput("");
    }
  };

  const getButtonStyles = (buttonName) => {
    const styles = {
      backgroundColor: isButtonClicked(buttonName) ? "#F1F9FE" : "",
      fontWeight: isButtonClicked(buttonName) ? "500" : "normal",
    };
    return styles;
  };

  const getIconStyles = (buttonName) => {
    const iconStyle = {
      fontSize: isButtonClicked(buttonName) ? "11.6px" : "10px",
    };
    return { ...addStyle, ...iconStyle };
  };

  const handleKeyDown = (e) => {
    // Add location on Enter, Space, or Comma
    if (e.key === "Enter" || e.key === " " || e.key === ",") {
      e.preventDefault();
      // Add location if not empty and not a duplicate
      const newLocation = locationValue.trim();
      if (newLocation && !locations.includes(newLocation)) {
        setLocations([...locations, newLocation]);
        setLoctionValue("");
        // Keep input focused
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }
  };

  const handleRemoveLocation = (locationToRemove) => {
    setLocations(locations.filter((location) => location !== locationToRemove));
  };

  const handleTextFieldChange = (event) => {
    const { value } = event.target;
    const vals = value.split(",").map((val) => val);
    const newTags = vals.map((val) => ({ key: val, name: val }));
    setKeywordInput(value);
    setKeywordTags(newTags);
    if (vals.length === 0 || vals.every((val) => val === "")) {
      setKeywordTags([]);
      setKeywordInput("");
    }
  };

  const renderItem = (props) => {
    const tagStyles = {
      backgroundColor: "lightblue",
      borderRadius: "50px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      marginLeft: "5px",
    };
    const hoveredStyles = {
      backgroundColor: "lightcoral",
    };
    const isMandatoryIcon = mandatory ? true : props.item.isMandatory;
    return (
      <>
        {keywordToggle ? (
          <div>{props.item.name}</div>
        ) : (
          <div
            style={{
              ...tagStyles,
              ...(props.onRenderItemState?.isHovered && hoveredStyles),
            }}
            onMouseEnter={props.onRenderItemState?.onMouseEnter}
            onMouseLeave={props.onRenderItemState?.onMouseLeave}
            className={styles.TagPickerMain}
          >
            <div className={styles.starOption} onClick={() => starHandle(props.item.key)}>
              {isMandatoryIcon ? (
                <IconButton iconProps={{ iconName: "FavoriteStarFill" }} className={styles.starInputs} />
              ) : (
                <IconButton iconProps={{ iconName: "FavoriteStar" }} className={styles.starInputs} />
              )}
            </div>
            <div className={styles.pickerText}>{props.item.name}</div>
            {props.onRemoveItem && (
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onRemoveItem(props.item);
                }}
                className={styles.pickerButton}
                styles={{
                  root: {
                    color: "white",
                  },
                  icon: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };
 
  const submitHandler = () => {
    const searchData = {
      skills: itInputFields,
      allFields: experience,
    };
    candidate([], searchContent);
    onDismiss();
    axiosPrivateCall
      .post("/api/v1/adbSearch/boolean", searchContent)
      .then((response) => {
        candidate(response.data, searchContent);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const toggleText = () => {
    if (itText === "+ Add IT Skills") {
      setItText("IT Skills");
      setShowItFields(true);
      if (itInputFields.length > 1) {
        setItInputFields([{ id: 1, skill: "", experience: "", mustHave: false }]);
      }
    } else {
      setItText("+ Add IT Skills");
      setShowItFields(false);
    }
  };

  const iconProps = {
    iconName: "FavoriteStar",
  };
  const iconProps1 = {
    iconName: "FavoriteStarFill",
  };

  const AddExcludeHandle = () => {
    setExcludeOn(!ExcludeOn);
  };

  const handleDropdownHandler = (property, newValue) => {
    setExperience({
      ...experience,
      [property]: newValue.key,
    });
  };

  const handleTabKeyPress1 = (event) => {
    if ((event.key === "Tab" || event.key === ",") && excludeInput !== "") {
      event.preventDefault();
      const tags = excludeInput
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== "");
      if (tags.length > 0) {
        const newTags = tags.map((tag) => ({ key: tag, name: tag }));
        setExcludeTags([...excludeTags, ...newTags]);
      }
      setExcludeInput("");
    }
  };

  const generateNewFields = () => {
    const newInputFields = [...itInputFields];
    const lastId = newInputFields[newInputFields.length - 1].id;
    newInputFields.push({
      id: lastId + 1,
      skill: "",
      experience: "",
      mustHave: false,
    });
    setItInputFields(newInputFields);
  };

  const handleInputChan = (id, fieldName, newValue) => {
    const updatedInputFields = itInputFields.map((field) =>
      field.id === id ? { ...field, [fieldName]: newValue } : field
    );
    setItInputFields(updatedInputFields);
    if (id === itInputFields.length && fieldName === "skill" && newValue !== "") {
      setGenerateButtonVisible(true);
    } else {
      setGenerateButtonVisible(false);
    }
  };

  const handleExperienceChange = (id, value) => {
    const updatedInputFields = itInputFields.map((field) =>
      field.id === id ? { ...field, experience: value } : field
    );
    setItInputFields(updatedInputFields);
  };

  const handleButtonClick1 = (id) => {
    const rowToUpdate = itInputFields.find((field) => field.id === id);
    rowToUpdate.mustHave = !rowToUpdate.mustHave;
    const updatedInputFields = itInputFields.map((field) => (field.id === id ? rowToUpdate : field));
    setItInputFields(updatedInputFields);
  };

  const handleButtonClick = (id) => {
    if (id === 1) {
      setItInputFields((prevInputFields) =>
        prevInputFields.map((field) =>
          field.id === id ? { ...field, skill: "", experience: "", mustHave: false } : field
        )
      );
    } else {
      const updatedInputFields = itInputFields.filter((field) => field.id !== id);
      setItInputFields(updatedInputFields);
    }
  };

  const handleCompanyInput = (type) => (event) => {
    const { value } = event.target;
    const values = value.split(",").map((val) => val.trim());
    const newTags = values.map((val) => ({ key: val, name: val }));

    if (type === "include") {
      setIncludeCompanyInput(value);
      setIncludeCompanyTags(newTags);

      if (values.length === 0 || values.every((val) => val === "")) {
        setIncludeCompanyTags([]);
        setIncludeCompanyInput("");
      }
    } else {
      setExcludeCompanyInput(value);
      setExcludeCompanyTags(newTags);

      if (values.length === 0 || values.every((val) => val === "")) {
        setExcludeCompanyTags([]);
        setExcludeCompanyInput("");
      }
    }
  };

  const handleTextFieldChange2 = (event) => {
    const { value } = event.target;
    const vals = value.split(",").map((val) => val);
    const newTags = vals.map((val) => ({ key: val, name: val }));
    setSelectedTags3(newTags);
    if (vals.length === 0 || vals.every((val) => val === "")) {
      setSelectedTags3([]);
    }
  };

  const renderSecondItem = (props) => {
    const tagStyles = {
      backgroundColor: "lightblue",
      borderRadius: "50px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      marginLeft: "5px",
    };
    const hoveredStyles = {
      backgroundColor: "lightcoral",
    };
    return (
      <>
        {keywordToggle ? (
          <div>{props.item.name}</div>
        ) : (
          <div
            style={{
              ...tagStyles,
              ...(props.onRenderItemState?.isHovered && hoveredStyles),
            }}
            onMouseEnter={props.onRenderItemState?.onMouseEnter}
            onMouseLeave={props.onRenderItemState?.onMouseLeave}
            className={styles.TagPickerMain1}
          >
            <div className={styles.starOption} onClick={() => starHandle(props.item.key)}></div>
            <div className={styles.pickerText}>{props.item.name}</div>
            {props.onRemoveItem && (
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onRemoveItem(props.item);
                }}
                className={styles.pickerButton}
                styles={{
                  root: {
                    color: "white",
                  },
                  icon: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <Modal isOpen={searchPopUp} onDismiss={handleModalDismiss} isBlocking={false}>
      <div className={styles.search_container}>
        <div className={styles.ser_container}>
          <div className={styles.title}>Search Candidates</div>
          <div className={styles.closework} onClick={handleModalDismiss}>
            <Icon iconName="ChromeClose" className={styles.closeIcon} />
          </div>
        </div>
        <div className={styles.middle_container}>
          <div className={styles.keyword}>keywords</div>
          <div className={styles.message}>
            {message && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                onDismiss={() => setMessage(null)}
                styles={messageBarStyles}
              >
                {message}
              </MessageBar>
            )}
          </div>
          <Toggle
            className={styles.keyword_toggle}
            onText={<span style={{ color: "#1a1a40" }}>boolean off</span>}
            offText={<span style={{ color: "#1a1a40" }}>boolean off</span>}
            checked={keywordToggle}
            onChange={handleKeywordsToggle}
          />
        </div>
        <div className={styles.containerPop}>
          <div className={styles.text_container}>
            {keywordToggle ? (
              <div>
                <TextField
                  type="text"
                  placeholder="Enter keywords like skills, designation and company"
                  value={keywordInput ? keywordInput : keywordTags.map((tag) => tag.name)}
                  onChange={handleTextFieldChange}
                  styles={() =>
                    getTextFieldStyles(
                      "775px", // width
                      "2px", // marginTop
                      "775px" // maxWidth
                    )
                  }
                />
              </div>
            ) : (
              <TagPicker
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={() => []}
                selectedItems={keywordTags}
                onChange={onTagChange}
                onInputChange={handleInputChange}
                inputProps={{
                  placeholder:
                    keywordTags.length > 0
                      ? "Type another keyword"
                      : "Enter keywords like skills, designation and company",
                  className: styles.inputContainerWithTags,
                  onKeyDown: handleTabKeyPress,
                  value: keywordInput,
                }}
                onRenderItem={renderItem}
                styles={getTagPickerStyles("775px", "2px", "775px")}
              />
            )}
          </div>
        </div>
        <ClearButton index={0} selectedTags={keywordTags} className={styles.keyword_clear} />

        {keywordToggle ? (
          ""
        ) : (
          <div className={styles.addText}>
            <p className={styles.plustext} onClick={excludeOpen}>
              {showExclude ? "" : "+ Add Exclude Keywords"}
            </p>
            {showExclude && (
              <div className={styles.main}>
                {
                  <>
                    <p className={styles.exclude_keywords}>Exclude Keywords</p>
                    <TagPicker
                      removeButtonAriaLabel="Remove"
                      onResolveSuggestions={() => []}
                      selectedItems={excludeTags}
                      onChange={onTagChange1}
                      onInputChange={handleInputChange1}
                      inputProps={{
                        placeholder:
                          excludeTags.length > 0
                            ? "Type another keyword"
                            : "Enter keywords like skills, designation and company",
                        className: excludeTags.length > 0 ? styles.inputContainerWithTags : styles.inputContainerNoTags,
                        style: {
                          display: "flex",
                          alignItem: "center",
                          marginLeft: "2px",
                        },
                        onKeyDown: handleTabKeyPress1,
                        value: excludeInput,
                      }}
                      onRenderItem={renderSecondItem}
                      styles={getTagPickerStyles("775px", "2px", "775px")}
                    />
                  </>
                }
              </div>
            )}
          </div>
        )}
        <ClearButton index={1} selectedTags={excludeTags} className={styles.keyword_clear} />

        <div className={styles.four}>
          {itText === "+ Add IT Skills" ? (
            <Text className={styles.five} onClick={toggleText}>
              {itText}
            </Text>
          ) : (
            <div>
              <Text className={styles.it_skill}>{itText}</Text>
            </div>
          )}
          {showItFields && (
            <div>
              <p className={styles.skills_information}>
                <span>
                  <Icon iconName={InfoIcon.iconName} className={styles.it_IconStyle} />
                </span>
                Candidates often miss filling the IT skills section in their profiles. Searching for them based on IT
                skills might lead to limited search results.
              </p>
              {itInputFields.map((field) => (
                <div key={field.id}>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <div className={styles.skillcontainer}>
                      <div className={styles.starIcon}>
                        <IconButton
                          className={styles.iconskill}
                          iconProps={field.mustHave ? iconProps1 : iconProps}
                          onClick={() => handleButtonClick1(field.id)}
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          className={styles.skillstyle}
                          placeholder={`Add Skill`}
                          onChange={(e, newValue) => handleInputChan(field.id, "skill", newValue)}
                          styles={(props) =>
                            getTextFieldStyles(
                              "100%", // width
                              "2px", // marginTop
                              "100%" // maxWidth
                            )
                          }
                        />
                      </div>
                      <Dropdown
                        className={styles.dropskillstyle}
                        placeholder="Select Experience"
                        options={[
                          { key: "ANY", text: "ANY" },
                          { key: "1", text: "< 1 YEAR" },
                          { key: "1", text: "1 YEAR+" },
                          { key: "2", text: "2 YEARS+" },
                          { key: "3", text: "3 YEARS+" },
                          { key: "4", text: "4 YEARS+" },
                          { key: "5", text: "5 YEARS+" },
                        ]}
                        selectedKey={field.experience}
                        onChange={(e, item) => handleExperienceChange(field.id, item.key)}
                        styles={(props) =>
                          getDropdownStyles(
                            "195px", //width
                            null, //marginTop
                            "195px" //maxwidth
                          )
                        }
                      />
                      <IconButton
                        className={styles.iconskill}
                        iconProps={cancelIcon}
                        onClick={() => handleButtonClick(field.id)}
                      />
                    </div>
                  </Stack>
                </div>
              ))}
            </div>
          )}
          {generateButtonVisible && (
            <p className={styles.skillbutton} onClick={generateNewFields}>
              + Add Experience
            </p>
          )}
        </div>

        <div className={styles.wrapper}>
          <div className={styles.section}>
            <p className={styles.header}>Experience</p>
            <div className={styles.exp_header}>
              <p>Min Experience</p>
              <p>Max Experience</p>
            </div>
            <div className={styles.exp_field}>
              <TextField
                type="text"
                placeholder="Year"
                value={minExperienceYear}
                onChange={e => handleNumericInput(e.target.value, setMinExperienceYear)}
                styles={(props) =>
                  getTextFieldStyles(
                    "80px", // width
                    "2px", // marginTop
                    "80px" // maxWidth
                  )
                }
              />
              <TextField
                type="text"
                placeholder="Month"
                value={minExperienceMonth}
                onChange={e => handleNumericInput(e.target.value, setMinExperienceMonth, 11)}
                styles={(props) =>
                  getTextFieldStyles(
                    "80px", // width
                    "2px", // marginTop
                    "80px" // maxWidth
                  )
                }
              />
              <div className={styles.connector}>to</div>
              <TextField
                type="text"
                placeholder="Year"
                value={maxExperienceYear}
                onChange={e => handleNumericInput(e.target.value, setMaxExperienceYear)}
                styles={(props) =>
                  getTextFieldStyles(
                    "80px", // width
                    "2px", // marginTop
                    "80px" // maxWidth
                  )
                }
              />
              <TextField
                type="text"
                placeholder="Month"
                value={maxExperienceMonth}
                onChange={e => handleNumericInput(e.target.value, setMaxExperienceMonth,11)}
                styles={(props) =>
                  getTextFieldStyles(
                    "80px", // width
                    "2px", // marginTop
                    "80px" // maxWidth
                  )
                }
              />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.header}>Salary</div>
            <div className={styles.salary_sub_header}>
              <p>Minimum salary</p>
              <p>Maximum salary</p>
            </div>
            <div className={styles.fields}>
              <TextField
                type="text"
                placeholder="min salary"
                value={minSalary}
                onChange={e => handleNumericInput(e.target.value, setMinSalary)}
                className={styles.salary_input}
                styles={(props) =>
                  getTextFieldStyles(
                    "120px", // width
                    "2px", // marginTop
                    "120px" // maxWidth
                  )
                }
              />
              <div className={styles.salary_connector}>to</div>
              <TextField
                type="text"
                placeholder="max salary"
                value={maxSalary}
                onChange={e => handleNumericInput(e.target.value, setMaxSalary)}
                className={styles.salary_input}
                styles={(props) =>
                  getTextFieldStyles(
                    "120px", // width
                    "2px", // marginTop
                    "120px" // maxWidth
                  )
                }
              />
              <div className={styles.salary_lacs}>Lacs</div>
            </div>
          </div>
        </div>

        <div className={styles.location_container}>
          <div className={styles.keyword_title}>Current location of candidate</div>

          <div className={styles.location_chips_container}>
            {locations.map((location) => (
              <div key={location} className={styles.location_chip}>
                {location}
                <button onClick={() => handleRemoveLocation(location)} className={styles.location_chip_remove}>
                  ✕
                </button>
              </div>
            ))}

            <input
              ref={inputRef}
              type="text"
              value={locationValue}
              onChange={(e) => setLoctionValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Add location"
              className={styles.location_field}
            />
          </div>
        </div>

        <div className={styles.loction_checkbox_text}>
          <div className={styles.check_box1}>
            <div>
              <Checkbox
                id="myCheckbox1"
                checked={relocateCheckbox}
                onChange={handleCheckboxChange1}
                styles={customCheckboxStyles}
              />
            </div>
            <label htmlFor="myCheckbox1" className={styles.customLabel}>
              Includes candidates who prefer to relocate to above locations
            </label>
          </div>
        </div>
        <div className={styles.loction_checkbox_text}>
          <div className={styles.check_box1}>
            <div>
              <Checkbox
                id="myCheckbox2"
                checked={anywhereCheckBox}
                onChange={handleCheckboxChange2}
                styles={customCheckboxStyles}
              />
            </div>
            <label htmlFor="myCheckbox2" className={styles.customLabel}>
              Exclude candidates who have mentioned Anywhere in..
            </label>
          </div>
        </div>

        {/* Employment Details */}

        <div className={styles.dropdown_Text}>
          <div className={styles.employee_container}>
            <div className={styles.employee_title}>Employment Details</div>
            <div className={styles.arrowContainer}>
              <div className={styles.arrowStyles}>
                <div className={`arrow ${isFirstDivOpen ? "open" : ""}`}>
                  <Icon onClick={toggleFirstDiv} iconName={isFirstDivOpen ? "ChevronUp" : "ChevronDown"} />
                </div>
              </div>
            </div>
          </div>
          {isFirstDivOpen && (
            <div className={styles.Department}>
              <div className={styles.Department_keyword}>Department and Role</div>
              {renderDropdown(
                "Department",
                selectedRoles,
                setSelectedRoles,
                employmentDetails.jobRoles,
                "Search for department and role..."
              )}

              <div className={styles.Industry_keyword}>Industry</div>
              {renderDropdown(
                "Industry",
                selectedIndustries,
                setSelectedIndustries,
                employmentDetails.industryTypes,
                "Search for Industries..."
              )}

              <div className={styles.company_container}>
                <div className={styles.company_keyword}>Company</div>
                <div className={styles.toggle_alignment}>
                <CustomToggle 
        checked={companyToggle} 
        onChange={handleToggle(setCompanyToggle)} 
      />
                </div>
              </div>

              <div className={styles.topc}>
                <div className={styles.containerPop1}>
                  <div className={styles.text_container1}>
                    {companyToggle ? (
                      <TextField
                        type="text"
                        placeholder="Enter company Name"
                        value={includeCompanyInput || includeCompanyTags.map((tag) => tag.name)}
                        onChange={handleCompanyInput("include")}
                        styles={(props) => getTextFieldStyles("550px", "2px", "550px")}
                      />
                    ) : (
                      renderDropdown(
                        "Companies",
                        selectedCompanies,
                        setSelectedCompanies,
                        employmentDetails.companyNames,
                        "Search for companies..."
                      )
                    )}
                  </div>
                  <Dropdown
                    className={styles.workdropdown}
                    calloutProps={{ doNotLayer: true }}
                    options={companyoptions}
                    styles={(props) => getDropdownStyles("220px", "2px", "220px")}
                    selectedKey={dropdownSelections.company}
                    onChange={(e, newValue) => handleDropdownChange("company", e, newValue)}
                  />
                </div>
              </div>
              <ClearButton index={2} selectedTags={selectedCompanies} className={styles.clearText2} />

              {ExcludeOn ? (
                <div className={styles.all}>
                  <div className={styles.company_container}>
                    <div className={styles.exclude_company}>Exclude Company</div>
                    <div className={styles.toggle_alignment}>
                    <CustomToggle 
        checked={excludeToggle} 
        onChange={handleToggle(setExcludeToggle)} 
      />
                    </div>
                  </div>
                  <div className={styles.topc}>
                    <div className={styles.containerPop1}>
                      <div className={styles.text_container1}>
                        {excludeToggle ? (
                          <TextField
                            type="text"
                            placeholder="Enter exclude companies"
                            value={excludeCompanyInput || excludeCompanyTags.map((tag) => tag.name)}
                            onChange={handleCompanyInput("exclude")}
                            styles={(props) => getTextFieldStyles("550px", "2px", "550px")}
                          />
                        ) : (
                          renderDropdown(
                            "ExcludeCompanies",
                            selectedExcludeCompanies,
                            setSelectedExcludeCompanies,
                            employmentDetails.companyNames,
                            "Search for Exclude Companies..."
                          )
                        )}
                      </div>
                      <Dropdown
                        className={styles.workdropdown}
                        calloutProps={{ doNotLayer: true }}
                        options={companyoptions}
                        styles={(props) => getDropdownStyles("220px", "2px", "220px")}
                        selectedKey={dropdownSelections.excludeCompany}
                        onChange={(e, newValue) => handleDropdownChange("excludeCompany", e, newValue)}
                      />
                    </div>
                    <ClearButton index={3} selectedTags={selectedExcludeCompanies} className={styles.clearText2} />

                  </div>
                </div>
              ) : (
                <div className={styles.all}>
                  <div className={styles.exclude_title} onClick={AddExcludeHandle}>
                    + Add Exclude Company{" "}
                  </div>
                </div>
              )}
              <div className={styles.all}>
                <div className={styles.company_container}>
                  <div className={styles.designation_keyword}>Designation</div>
                  <div className={styles.toggle_alignment}>
                  <CustomToggle 
        checked={designationToggle} 
        onChange={handleToggle(setDesignationToggle)} 
      />
                  </div>
                </div>
              </div>

              <div className={styles.containerPop1}>
                <div className={styles.text_container1}>
                  {designationToggle ? (
                    <TextField
                      type="text"
                      placeholder="Enter keywords like skills, designation and company 222"
                      value={selectedTags3.map((tag) => tag.name)}
                      onChange={handleTextFieldChange2}
                      styles={(props) =>
                        getTextFieldStyles(
                          "550px", // width
                          "2px", // marginTop
                          "550px" // maxWidth
                        )
                      }
                    />
                  ) : (
                    renderDropdown(
                      "Desgination",
                      selectedDesgination,
                      setSelectedDesgination,
                      employmentDetails.jobRoles,
                      "Search for Exclude designation..."
                    )
                  )}
                </div>
                <Dropdown
                  className={styles.workdropdown}
                  calloutProps={{ doNotLayer: true }}
                  options={companyoptions}
                  styles={(props) => getDropdownStyles("220px", "2px", "220px")}
                  selectedKey={dropdownSelections.designation}
                  onChange={(e, newValue) => handleDropdownChange("designation", e, newValue)}
                />
              </div>
              <ClearButton index={4} selectedTags={selectedDesgination} className={styles.clearText2} />


              <p className={styles.notice_keyword}>Notice Period/ Availabilty to join</p>
              <div className={styles.divnoticeperiod}>
                <button style={getButtonStyles("any")} onClick={() => handleButtonClickz("any")}>
                  Any
                </button>
                <button style={getButtonStyles("0-15")} onClick={() => handleButtonClickz("0-15")}>
                  0-15 days
                  <Icon iconName={isButtonClicked("0-15") ? "Accept" : "Add"} style={getIconStyles("0-15")} />
                </button>
                <button style={getButtonStyles("1 month")} onClick={() => handleButtonClickz("1 month")}>
                  1 month
                  <Icon iconName={isButtonClicked("1 month") ? "Accept" : "Add"} style={getIconStyles("1 month")} />
                </button>
                <button style={getButtonStyles("2 month")} onClick={() => handleButtonClickz("2 month")}>
                  2 months
                  <Icon iconName={isButtonClicked("2 month") ? "Accept" : "Add"} style={getIconStyles("2 month")} />
                </button>
                <button style={getButtonStyles("3 month")} onClick={() => handleButtonClickz("3 month")}>
                  3 months
                  <Icon iconName={isButtonClicked("3 month") ? "Accept" : "Add"} style={getIconStyles("3 month")} />
                </button>
                <button
                  style={getButtonStyles("More than 3 month")}
                  onClick={() => handleButtonClickz("More than 3 month")}
                >
                  More than 3 months
                  <Icon
                    iconName={isButtonClicked("More than 3 month") ? "Accept" : "Add"}
                    style={getIconStyles("More than 3 month")}
                  />
                </button>
                <button
                  style={getButtonStyles("Currently serving notice period")}
                  onClick={() => handleButtonClickz("Currently serving notice period")}
                >
                  Currently serving notice period
                  <Icon
                    iconName={isButtonClicked("Currently serving notice period") ? "Accept" : "Add"}
                    style={getIconStyles("Currently serving notice period")}
                  />
                </button>
              </div>
            </div>
          )}
          <hr className={styles.hr} />
          <div className={styles.AdditionalDetails}>
            <div className={styles.DiversityAddHead}>Diversity and Additional Details</div>
            <div className={styles.arrowStyles1}>
              <div className={`arrow ${isSecondDivOpen ? "open" : ""}`}>
                <Icon onClick={toggleThirdtDiv} iconName={isThirdDivOpen ? "ChevronUp" : "ChevronDown"} />
              </div>
            </div>
          </div>

          {isThirdDivOpen && (
            <div className={styles.AdddetailCon}>
              <div className={styles.container}>
                <div className={styles.genderContainer}>
                  <div className={styles.Diversity_keyword}>Diversity details</div>
                  <div className={styles.GenderCon}>
                    <p>Gender</p>
                    <div className={styles.middButtonCon}>
                      <div className={styles.buttonsStyles}>
                        <DefaultButton
                          borderless={true}
                          text="All"
                          style={buttonStyle("All")}
                          onClick={() => handleButtonClickGender("All")}
                        />
                        <DefaultButton
                          borderless={true}
                          text="Male"
                          style={buttonStyle("Male")}
                          onClick={() => handleButtonClickGender("Male")}
                        />
                        <DefaultButton
                          borderless={true}
                          text="Female"
                          style={buttonStyle("Female")}
                          onClick={() => handleButtonClickGender("Female")}
                        />
                      </div>

                      <div className={styles.workDetails}>
                        <div className={styles.workDetails_keyword}>work details</div>
                        <p className={styles.workDetails_subHead}>Show candidates seeking</p>
                        <div className={styles.workDetails_container}>
                          <Dropdown
                            className={styles.workdropdown}
                            placeholder="Job type"
                            calloutProps={{ doNotLayer: true }}
                            options={optionsjob}
                            styles={(props) =>
                              getDropdownStyles(
                                "170px", //width
                                "2px", //marginTop
                                "170px" //maxwidth
                              )
                            }
                            selectedKey={experience.employment_type}
                            onChange={(e, newValue) => handleDropdownHandler("employment_type", newValue)}
                          />
                          <Dropdown
                            className={styles.workdropdown}
                            placeholder="Work Mode"
                            options={workmodeoptions}
                            styles={(props) =>
                              getDropdownStyles(
                                "170px", //width
                                "2px", //marginTop
                                "170px" //maxwidth
                              )
                            }
                            selectedKey={experience.work_model}
                            onChange={(e, newValue) => handleDropdownHandler("work_model", newValue)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.displayContainer}>
                  <div className={styles.displayhead}>Display details</div>
                  <div className={styles.showHead}>
                    <p>Show only candidates with</p>
                  </div>
                  <div className={styles.attachbtn}>
                    {renderButton("resume", "Attached Resume")}
                    {renderButton("mobile", "Verified Mobile Number")}
                    {renderButton("email", "Verified Email ID")}
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <hr className={styles.hr} />
          <br />
          <div className={styles.saveButton}>
            <PrimaryButton className={styles.searchCandidate} text="Search Candidate" onClick={submitHandler} />
          </div>
          <br />
        </div>
      </div>
    </Modal>
  );
};

export default SearchPopup;
